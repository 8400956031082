
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as accessoriesRp4NBnnNXoMeta } from "/opt/buildhome/repo/pages/accessories.vue?macro=true";
import { default as addressesoKJH00J7FuMeta } from "/opt/buildhome/repo/pages/account/addresses.vue?macro=true";
import { default as documents54TVCPUJl6Meta } from "/opt/buildhome/repo/pages/account/documents.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as notificationsvs4SYXstdeMeta } from "/opt/buildhome/repo/pages/account/notifications.vue?macro=true";
import { default as _91id_93DlpXRTT1ZQMeta } from "/opt/buildhome/repo/pages/account/orders/[id].vue?macro=true";
import { default as indexrQAP8wwvotMeta } from "/opt/buildhome/repo/pages/account/orders/index.vue?macro=true";
import { default as _91slug_93x8nwzxMykbMeta } from "/opt/buildhome/repo/pages/blog/[slug].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as bow_45techkKZKVMZmRKMeta } from "/opt/buildhome/repo/pages/bow-tech.vue?macro=true";
import { default as _91slug_935zS6qZnsD9Meta } from "/opt/buildhome/repo/pages/brand/[slug].vue?macro=true";
import { default as brandsYEDLu36Hh0Meta } from "/opt/buildhome/repo/pages/brands.vue?macro=true";
import { default as _91_46_46_46slug_93pPumF5MSdVMeta } from "/opt/buildhome/repo/pages/category/[...slug].vue?macro=true";
import { default as shootingDMLhK0NzULMeta } from "/opt/buildhome/repo/pages/category/shooting.vue?macro=true";
import { default as indexQrPIrenMTVMeta } from "/opt/buildhome/repo/pages/checkout/index.vue?macro=true";
import { default as successHJscjFXVdfMeta } from "/opt/buildhome/repo/pages/checkout/success.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as dealersHMbiYJMRvjMeta } from "/opt/buildhome/repo/pages/dealers.vue?macro=true";
import { default as _91slug_93WAfRGfUxmXMeta } from "/opt/buildhome/repo/pages/group/[slug].vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93fF0wUx7TgkMeta } from "/opt/buildhome/repo/pages/page/[...slug].vue?macro=true";
import { default as _91slug_93MIk10RbmKJMeta } from "/opt/buildhome/repo/pages/product/[slug].vue?macro=true";
import { default as reviewsjKEmcRW7bYMeta } from "/opt/buildhome/repo/pages/reviews.vue?macro=true";
import { default as searchW2JMgpGXtYMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as _91slug_938wNH3MjeF9Meta } from "/opt/buildhome/repo/pages/videos/[slug].vue?macro=true";
import { default as indexGbbA7KS0lnMeta } from "/opt/buildhome/repo/pages/videos/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "accessories",
    path: "/accessories",
    component: () => import("/opt/buildhome/repo/pages/accessories.vue")
  },
  {
    name: "account-addresses",
    path: "/account/addresses",
    meta: addressesoKJH00J7FuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/addresses.vue")
  },
  {
    name: "account-documents",
    path: "/account/documents",
    meta: documents54TVCPUJl6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/account/documents.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notificationsvs4SYXstdeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/notifications.vue")
  },
  {
    name: "account-orders-id",
    path: "/account/orders/:id()",
    component: () => import("/opt/buildhome/repo/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders",
    path: "/account/orders",
    meta: indexrQAP8wwvotMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/orders/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "bow-tech",
    path: "/bow-tech",
    component: () => import("/opt/buildhome/repo/pages/bow-tech.vue")
  },
  {
    name: "brand-slug",
    path: "/brand/:slug()",
    meta: _91slug_935zS6qZnsD9Meta || {},
    alias: ["/manufacturer/:slug","/b/:slug"],
    component: () => import("/opt/buildhome/repo/pages/brand/[slug].vue")
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/opt/buildhome/repo/pages/brands.vue")
  },
  {
    name: "category-slug",
    path: "/category/:slug(.*)*",
    meta: _91_46_46_46slug_93pPumF5MSdVMeta || {},
    alias: ["/c/:slug(.*)*"],
    component: () => import("/opt/buildhome/repo/pages/category/[...slug].vue")
  },
  {
    name: "category-shooting",
    path: "/category/shooting",
    meta: shootingDMLhK0NzULMeta || {},
    alias: ["/c/shooting"],
    component: () => import("/opt/buildhome/repo/pages/category/shooting.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexQrPIrenMTVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/index.vue")
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    meta: successHJscjFXVdfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/success.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "dealers",
    path: "/dealers",
    component: () => import("/opt/buildhome/repo/pages/dealers.vue")
  },
  {
    name: "group-slug",
    path: "/group/:slug()",
    component: () => import("/opt/buildhome/repo/pages/group/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug(.*)*",
    meta: _91_46_46_46slug_93fF0wUx7TgkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/page/[...slug].vue")
  },
  {
    name: "product-slug",
    path: "/product/:slug()",
    meta: _91slug_93MIk10RbmKJMeta || {},
    alias: ["/p/:slug"],
    component: () => import("/opt/buildhome/repo/pages/product/[slug].vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/opt/buildhome/repo/pages/reviews.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "videos-slug",
    path: "/video/:slug",
    meta: _91slug_938wNH3MjeF9Meta || {},
    alias: ["/v/:slug","/videos/:slug"],
    component: () => import("/opt/buildhome/repo/pages/videos/[slug].vue")
  },
  {
    name: "videos",
    path: "/videos",
    component: () => import("/opt/buildhome/repo/pages/videos/index.vue")
  }
]