<template>
  <div>
    <NuxtLoadingIndicator
      color="#ed1c24"
      :height="5"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { defineOrganization, defineAddress } from 'nuxt-schema-org/schema';
import { LazyDialogAgeGate } from '#components';

const ageStore = useAgeStore();
const { $bus } = useNuxtApp();
const { dialog } = useQuasar();

$bus.once('app:interaction', () => ageStore.check(18) || dialog({
  component: LazyDialogAgeGate,
  componentProps: {
    age: 18,
  },
}));

useSchemaOrg([
  defineOrganization({
    '@type': ['Organization', 'Store', 'OnlineStore'],
    'name': 'KYGUNCO',
    'alternateName': 'Kentucky Gun Company',
    'logo': '/logos/kygunco.svg',
    'telephone': '+1-502-348-3594',
    'email': 'info@kygunco.com',
    'contactPoint': [
      {
        '@type': 'ContactPoint',
        'contactType': 'Customer Service',
        'telephone': '+1-502-348-3594',
        'email': 'info@kygunco.com',
        'availableLanguage': ['English'],
        'hoursAvailable': {
          '@type': 'OpeningHoursSpecification',
          'dayOfWeek': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          'opens': '09:00',
          'closes': '17:00',
        },
      },
    ],
    'openingHoursSpecification': {
      dayOfWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      opens: '09:00',
      closes: '18:00',
    },
    'foundingDate': '1946-01-01',
    'address': defineAddress({
      streetAddress: '401 Glenwood Drive',
      addressLocality: 'Bardstown',
      addressRegion: 'KY',
      postalCode: '40004',
      addressCountry: 'US',
    }),
    'hasMerchantReturnPolicy': {
      '@type': 'MerchantReturnPolicy',
      'name': 'Standard Return Policy',
      'inStoreReturnsOffered': true,
      'merchantReturnDays': '30',
      'returnPolicyCategory': 'MerchantReturnFiniteReturnWindow',
      'returnMethod': ['ReturnByMail', 'ReturnInStore'],
      'returnFees': 'FreeReturn',
      'returnPolicyCountry': {
        '@type': 'Country',
        'name': ['US'],
      },
      'refundType': ['ExchangeRefund', 'FullRefund', 'StoreCreditRefund'],
      'applicableCountry': 'US',
    },
    'shippingDetails': {
      '@type': 'OfferShippingDetails',
      'shippingRate': {
        '@type': 'MonetaryAmount',
        'value': '0',
        'currency': 'USD',
      },
      'shippingDestination': {
        '@type': 'DefinedRegion',
        'addressCountry': ['US'],
      },
      'deliveryTime': {
        '@type': 'ShippingDeliveryTime',
        'handlingTime': {
          '@type': 'QuantitativeValue',
          'minValue': 2,
          'maxValue': 10,
          'unitCode': 'DAY',
        },
        'transitTime': {
          '@type': 'QuantitativeValue',
          'minValue': 2,
          'maxValue': 7,
          'unitCode': 'DAY',
        },
      },
    },
    'paymentAccepted': [
      'Credit Card',
      'Credova',
    ],
    'currenciesAccepted': ['USD'],
    'aggregateRating': {
      '@type': 'AggregateRating',
      'worstRating': 1,
      'bestRating': 5,
      'ratingValue': '4.7',
      'reviewCount': '6951',
    },
    // hasOfferCatalog: {
    //   '@type': 'OfferCatalog',
    //   'name': 'ModernHome Product Catalog',
    //   'url': '/search',
    // },
    'sameAs': [
      'https://x.com/kygunco',
      'https://www.facebook.com/kentuckygunco',
      'https://www.instagram.com/kygunco',
      'https://www.youtube.com/@thekentuckygunco',
      'https://rumble.com/c/KYGUNCO',
    ],
  }),
]);
</script>
