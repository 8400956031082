import payload_plugin_qLmFnukI99 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/opt/buildhome/repo/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_JRId4KOeUL from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import strapi_m0ehjFxJWT from "/opt/buildhome/repo/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.js";
import plugin_client_UYiXMU8ZyN from "/opt/buildhome/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import nuxt_plugin_6wEQMY3tee from "/opt/buildhome/repo/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_sb2LSEEy8f from "/opt/buildhome/repo/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import _00_ecommerce_h7TcpfkE28 from "/opt/buildhome/repo/plugins/00.ecommerce.ts";
import _00_typesense_an4G8zVhq4 from "/opt/buildhome/repo/plugins/00.typesense.ts";
import accessibly_client_2tJgKXqcfL from "/opt/buildhome/repo/plugins/accessibly.client.ts";
import analytics_client_mtqVw3YRXk from "/opt/buildhome/repo/plugins/analytics.client.ts";
import avantlink_IcQGxZmS8Z from "/opt/buildhome/repo/plugins/avantlink.ts";
import bus_RrsLoRnN1Z from "/opt/buildhome/repo/plugins/bus.ts";
import clarity_FoYTnyX1Oc from "/opt/buildhome/repo/plugins/clarity.ts";
import credova_6kutWhLazg from "/opt/buildhome/repo/plugins/credova.ts";
import klaviyo_aSAUWaoYmN from "/opt/buildhome/repo/plugins/klaviyo.ts";
import kount_FS9jK2y5J6 from "/opt/buildhome/repo/plugins/kount.ts";
import marketing_1nGyY8ktKC from "/opt/buildhome/repo/plugins/marketing.ts";
import meta_EScpsfw7On from "/opt/buildhome/repo/plugins/meta.ts";
import tawk_tUlyaFnual from "/opt/buildhome/repo/plugins/tawk.ts";
import defaults_M8ptihKv0z from "/opt/buildhome/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  strapi_m0ehjFxJWT,
  plugin_client_UYiXMU8ZyN,
  nuxt_plugin_6wEQMY3tee,
  plugin_sb2LSEEy8f,
  _00_ecommerce_h7TcpfkE28,
  _00_typesense_an4G8zVhq4,
  accessibly_client_2tJgKXqcfL,
  analytics_client_mtqVw3YRXk,
  avantlink_IcQGxZmS8Z,
  bus_RrsLoRnN1Z,
  clarity_FoYTnyX1Oc,
  credova_6kutWhLazg,
  klaviyo_aSAUWaoYmN,
  kount_FS9jK2y5J6,
  marketing_1nGyY8ktKC,
  meta_EScpsfw7On,
  tawk_tUlyaFnual,
  defaults_M8ptihKv0z
]